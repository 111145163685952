import { Injectable } from '@angular/core';

import { PharHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PermissionService {

  constructor(
    private http: PharHttp,
    private config: AppConfig
  ) {
  }

  getAllPermissions(type: string): Observable<any> {
    return this.http.get(this.config.buildUri('permission') + '/AllPermissions?permission=' + type);
  }

  getAllPermissionsGroup(type: string): Observable<any> {
    return this.http.get(this.config.buildUri('permission') + '/AllPermissionsGroup?permission=' + type);
  }

  getAllNavigatorPermissions(withGroup: boolean = false): Observable<any> {
    if (withGroup) {
      return this.getAllPermissionsGroup('Navigator');
    }
    return this.getAllPermissions('Navigator');
  }

  getAllManagerPermissions(withGroup: boolean = false): Observable<any> {
    if (withGroup) {
      return this.getAllPermissionsGroup('Manager');
    }
    return this.getAllPermissions('Manager');
  }

  getPermissionsPerRole(roleId: number): Observable<any> {
    return this.http.get(this.config.buildUri('permission') + '/PermissionsPerRole?roleId=' + roleId);
  }

  getUserPermissions(): Observable<any> {
    return this.http.get(this.config.buildUri('permission') + '/AllPermissionsPerUser');
  }

}
