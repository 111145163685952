import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PermissionService } from './permission.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  // declarations: [PermissionsGridViewComponent],
  // exports: [PermissionsGridViewComponent],
  providers: [PermissionService]
})
export class PermissionModule {
}
